@import '../../styles/customMediaQueries.css';

/* Can be given to Topbar when a modal is opened
 * so that the Topbar is not rendered on top of it.*/
.topbarBehindModal {
  z-index: initial;
}

.topbar {
  width: 100%;

  /* Top Bar's drop shadow should always be visible */
  z-index: var(--zIndexTopbar);
  position: relative;

  @media (--viewportMedium) {
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--zIndexTopbar);
  }
}

.container {
  padding-top: 0px !important;
  min-height: calc(100vh - var(--topbarHeight));
  @media (max-width: 768px) {
    padding-top: 0px;
  }

  @media (--viewportMedium) {
    position: relative;
    z-index: 0;
    padding-top: var(--topbarHeight);
    min-height: calc(100vh - var(--topbarHeight));
  }
  @media (--viewportLarge) {
    padding-top: var(--topbarHeightDesktop);
    min-height: calc(100vh - var(--topbarHeightDesktop));
  }
  &.toggleMap {
    & .searchResultContainer {
      max-width: 100%;
    }
  }
}

/* Container for side and content */
.layoutWrapperContainer {
  composes: container;
  /* parent aka root is flexbox, this container takes all available space */
  flex-shrink: 0;

  /* This container uses flexbox layout */
  display: flex;
  flex-direction: column;
  width: 100%;

  /* When side nav is shown as a column, Topbar's box-shadow should be visible */
  z-index: calc(var(--zIndexTopbar) - 1);

  @media (--viewportMedium) {
    flex-direction: row;
    justify-content: center;
  }
}

.layoutWrapperFilterColumn {
  display: none;

  @media (--viewportMedium) {
    /* Layout */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 24px 0 0 24px;
  }
  @media (--viewportLarge) {
    /* Layout */
    padding: 40px 0 0 36px;

    flex-direction: column;
    justify-content: flex-start;
    border: none;
    box-shadow: none;
    background-color: transparent;
  }

  @media (min-width: 1440px) {
    padding: 40px 0 0 calc((100vw - 1368px) / 2);
  }
  @media (--viewportXLarge) {
    padding: 40px 0 0 calc((100vw - 1800px) / 2);
  }
}

.filterColumnContent {
  width: 210px;
  padding: 8px 24px 0 0;

  @media (--viewportMedium) {
    padding: 8px 24px 24px 0;
  }

  @media (--viewportLarge) {
    width: 240px;
    padding: 8px 36px 24px 0;
  }

  @media (min-width: 1440px) {
    padding: 8px 60px 24px 0;
  }
}
.filter:first-child {
  padding-top: 0;
}

.resetAllButton {
  composes: h5 from global;
  font-weight: var(--fontWeightMedium);
  color: var(--colorGrey300);

  /* Layout */
  display: inline;
  margin: 17px 0 0 0;
  padding: 0;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;
  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--colorGrey700);
  }

  @media (--viewportMedium) {
    margin: 24px 0 0 0;
    padding: 3px 0 5px 0;
  }
}

.layoutWrapperMain {
  /* Expand to the full remaining width of the viewport */
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0 24px 0 24px;

  @media (--viewportMedium) {
    /**
     * Calculate right padding to match Footer right border
     * and left padding together with border should be 60px.
     */
    padding: 24px 24px 0 24px;
    background-color: var(--colorWhite);
    border-left-width: 1px;
    border-left-style: solid;
    border-left-color: var(--colorGrey100);
  }

  @media (--viewportLarge) {
    /**
     * Calculate right padding to match Footer right border
     * and left padding together with border should be 60px.
     */
    padding: 40px 36px 0 36px;
  }

  @media (min-width: 1440px) {
    padding: 40px calc((100vw - 1368px) / 2) 0 59px;
  }
  @media (--viewportXLarge) {
    padding: 40px calc((100vw - 1800px) / 2) 0 59px;
  }
}
.searchMainContainer {
  width: 100%;
  flex-grow: 1;
  display: flex;
  /* @media (--viewportMedium) {
    padding-top: var(--topbarHeightDesktop);
  } */

  & .searchResultContainer {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 2px 0 0 0;
    width: 100%;
    @media (--viewportMedium) {
      padding: 0;
      /**
     * .container is using flexbox,
     * This specifies that searchResultContainer is taking 60% from the viewport width
     */
      max-width: 50%;
    }

    @media (--viewportLarge) {
      max-width: 50%;
    }
  }
  & .mapPanel {
    width: 100%;
    @media (--viewportMedium) {
      /**
       * .container is using flexbox,
       * This specifies that mapPanel is taking 34% from the viewport width
       */
      max-width: 50%;

      /* Own layout settings */
      display: flex;
      height: 100%;
    }

    @media (--viewportLarge) {
      max-width: 50%;
    }
  }
}
.error {
  color: var(--colorFail);
}

.searchString {
  /* Search string should not break on white spaces - i.e. line-break should happen before. */
  white-space: nowrap;
}

.mainPanel {
  display: none;

  @media (--viewportMedium) {
    display: flex;
  }
}

.mainPanelMapVariant {
  composes: mainPanel;

  @media (--viewportMedium) {
    padding: 18px 24px 0 24px;
    margin-bottom: 21px;
  }

  @media (--viewportLarge) {
    padding: 23px 30px 0 30px;
  }
}

.searchFiltersMobileMap {
  padding: 16px 24px;

  @media (--viewportMedium) {
    display: none;
  }
}

.searchFiltersMobileList {
  padding: 16px 0;

  @media (--viewportMedium) {
    display: none;
  }
}

.searchFiltersPanel {
  flex-grow: 1;
  display: flex;
  padding: 0 24px 24px 24px;

  @media (--viewportLarge) {
    padding: 0 30px 30px 30px;
  }
}

.listings {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.listingsForGridVariant {
  composes: listings;
}

.listingsForMapVariant {
  composes: listings;
  padding: 0 24px;

  @media (--viewportLarge) {
    padding: 0 30px;
  }
}

.newSearchInProgress {
  opacity: 0.1;
}

.searchListingsPanel {
  flex-grow: 1;
  &.mapToggle {
    border: solid red;
  }
}

.mapWrapper {
  width: 100%;
  height: 100%;
}

.map {
  width: 100%;
  height: 100vh;

  @media (--viewportMedium) {
    /* Map is fixed so that it doesn't scroll along search results */
    position: fixed;
    top: calc(var(--topbarHeightDesktop) * 2);
    right: 0;

    /* Fixed content needs width relative to viewport */
    width: 50%;
    height: calc(100vh - var(--topbarHeightDesktop));
  }

  @media (--viewportLarge) {
    width: 50%;
  }
}

.searchIcon {
  width: 35px;
  height: 35px;
  border-radius: 100px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0;
  min-height: auto;
  & > svg {
    width: 16px;
    height: 16px;
    & > path {
      fill: var(--colorWhite);
    }
  }
}
.searchPageFilters {
  padding: 16px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--colorGrey50);
  box-shadow: var(--boxShadow);
  position: sticky;
  left: 0;
  width: 100%;
  z-index: 1;
  z-index: var(--zIndexTopbar);
  width: 100%;
  top: var(--topbarHeight);
  border-top: solid 1px var(--colorGrey100);
  @media (--viewportMedium) {
    top: var(--topbarHeight);
  }
  @media (--viewportLarge) {
    top: var(--topbarHeightDesktop);
  }
  @media (--viewportLargeWithPaddings) {
    padding: 16px 30px;
  }
  & .filterSection {
    flex-grow: 0.5;
    & .searchPageForm {
    }
    & .searchPageFilter {
      display: flex;
      align-items: center;
      background-color: var(--colorWhite);
      border: solid 1px var(--colorGrey100);
      padding: 2px 6px;
      border-radius: 5px;
      &:hover {
        border-color: var(--colorGrey200);
      }
      & .locationAddress {
      }
    }
  }
  & .middleFilters {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.toggleMap {
  cursor: pointer;
  & > svg {
    & path,
    & rect {
      stroke: var(--colorGrey700);
    }
  }
  &:hover {
    cursor: pointer;
    & > svg {
      & path,
      & rect {
        stroke: var(--marketplaceColor);
      }
    }
  }
}
.searchResultSummary {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  & .totalCount {
    font-size: 20px;
    color: var(--colorGrey800);
    font-weight: var(--fontWeightBold);
  }
}
